/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs 
|------------------
*/
import Accordion from 'accordion-js';
import {gsap,CSSPlugin,SplitText,ScrollSmoother,ScrollTrigger} from "gsap/all";
gsap.registerPlugin(ScrollSmoother, ScrollTrigger, SplitText, CSSPlugin);
import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';


/*
|
| Page transition selector
|-----------------------
*/

const overlayPath = document.querySelector('.overlay__path');

/*
|
| Smooth scroll gsap
|-----------------------
*/

const freeAudit = document.getElementById("free-audit");
  const Smoother = function() {
      if (freeAudit) {
          ScrollSmoother.create({
              smooth:3,
              smoothTouch: false,
              normalizeScroll: true,
              ignoreMobileResize: true,
              effects: true,
              onUpdate: function(ScrollSmoother) {
                  freeAudit.style.transform = "rotate(" + ScrollSmoother.progress * 360 + "deg)";
              }
          });
      }
  };



/*
|
| Kill Scrolltrigger 
|-------------------
*/

const killScrollTriggers = function() {
    let triggers = ScrollTrigger.getAll();
    triggers.forEach(function(trigger) {
        trigger.kill(true);
    });
};


/*
|
| accordeon 
|-------------------
*/

function accordeon(container) {
    const accordions = Array.from(container.querySelectorAll('.accordion-container'));
    const block_accordions = Array.from(container.querySelectorAll('.inner_ac'));

    const accordionsAccordion = new Accordion(accordions, {
        duration: 200,
        showMultiple: false,
      
        onOpen: function(currentElement) {
          ScrollTrigger.refresh(currentElement);
          const button = currentElement.querySelector('.replace-ac');
          if (button) {
            button.textContent = 'Fermer';
          }
        },

        onClose: function(currentElement) {
          ScrollTrigger.refresh(currentElement);
          const button = currentElement.querySelector('.replace-ac');
          if (button) {
            button.textContent = 'Lire';
          }
        },
  
    });
      
    const blockAccordionsAccordion = new Accordion(block_accordions, {
        duration: 300,
        showMultiple: false,

        onOpen: function(currentElement) {
            ScrollTrigger.refresh(currentElement);
        },
        onClose: function(currentElement) {
            ScrollTrigger.refresh(currentElement);
        },
    });
}



/*
|
| Application du marker blog
|---------------------------
*/

function Filtre_blog_active() {
    var current_url = window.location.href;
    jQuery('.filters-container a').each(function() {
      var $this = jQuery(this);
      if ($this.attr('href') === current_url) {
        $this.addClass('active');
      } else {
        $this.removeClass('active');
      }
    });
}


/*
|
| Application du marker menu
|---------------------------
*/

function markCurrentPageLinks() {
    const currentPageUrl = window.location.href;
    const links = document.querySelectorAll('#header-nav a');
    const navUrls = Array.from(links).map(link => link.href);

    let foundMatch = false;
    links.forEach(link => {
        link.classList.toggle('visited', link.href === currentPageUrl);
        foundMatch = foundMatch || link.href === currentPageUrl;
    });

    if (!foundMatch) {
        links.forEach(link => {
            link.classList.remove('visited');
        });
    }
}



/*
|
| Importing Utils
|-------------------
*/


import {Fade, Line, Pop, Footer, Drag_sticker} from '@components/section_effect';
import {Menu_services, hideMegaMenu} from '@components/menu-services';
import {Slider_Web, Slider_Expertise} from '@components/slider';

import Cursor from '@components/cursor';


/*
|
| Importing App files
|----------------------
*/
import Main from '@pages/main';

import Home from '@pages/home';
import About from '@pages/about';
import Contact from '@pages/contact';
import Faq from '@pages/faq';

import News from '@pages/archive-news';
import NewsCat from '@pages/archive-news-category';
import Article from '@pages/single-news';

import Projects from '@pages/archive-projects';
import Project from '@pages/single-projects';

import Expertise from '@pages/single-expertise';


/*
|
| Global 
|----------
*/
Main()


/*
|
| Barba Hook 
|-----------
*/


/*
|
| after Leave
|-------------
*/
barba.hooks.afterLeave(() => {
    killScrollTriggers();
    hideMegaMenu(); 
    $('body').removeClass('is-menu') 
})


/*
|
| before Enter
|-------------
*/
barba.hooks.beforeEnter((data) => {

// Retour en haut de page
window.scrollTo(0, 0);

// Séparer ligne par ligne
const splitLinesPresentation = new SplitText(".line-up-animation", {
    type: "lines",
    linesClass: "line-up"
});

$(".line-up").wrap('<div class="overflow-hidden-line">');


// Séparer ligne par ligne
const splitLinesPresentationmenu = new SplitText(".line-up-animation-menu", {
  type: "lines",
  linesClass: "line-up-menu"
});

$(".line-up-menu").wrap('<div class="overflow-hidden-line">');

// Marquer les liens de la page courante
markCurrentPageLinks()



// const elements = data.next.container.querySelectorAll('[data-bg="white"], [data-bg="black"]');
// const header = document.querySelector('header');
// let previousClass = '';

//   elements.forEach(element => {
//     const { dataset: { bg } } = element;

  
//     gsap.to(header, {
//       scrollTrigger: {
//         trigger: element,
//         start: 'top top-=-60',
//         markers:false,
//         onEnter: () => {
//           const newHeaderClass = (bg === 'white') ? 'header-light' : 'header-black';

//           if (previousClass && previousClass !== newHeaderClass) {
//             header.classList.remove(previousClass);
//           }

//           header.classList.add(newHeaderClass);
//           previousClass = newHeaderClass;
          
//         },
//         onEnterBack: () => {
//           const newHeaderClass = (bg === 'white') ? 'header-light' : 'header-black';

//           if (previousClass && previousClass !== newHeaderClass) {
//             header.classList.remove(previousClass);
//           }

//           header.classList.add(newHeaderClass);
//           previousClass = newHeaderClass;
          
//         }


//     }
//     });


//     gsap.to(header, {
//       scrollTrigger: {
//         trigger: element,
//         start: 'bottom top',
//         markers:true,
//         onLeave: () => {
//           const newHeaderClass = (bg === 'white') ? 'header-light' : 'header-black';

//           if (previousClass && previousClass !== newHeaderClass) {
//             header.classList.remove(previousClass);
//           }

//           header.classList.add(newHeaderClass);
//           previousClass = newHeaderClass;
          
//         },
//         onLeaveBack: () => {
//           const newHeaderClass = (bg === 'white') ? 'header-light' : 'header-black';

//           if (previousClass && previousClass !== newHeaderClass) {
//             header.classList.remove(previousClass);
//           }

//           header.classList.add(newHeaderClass);
//           previousClass = newHeaderClass;
          
//         }


//     }
//     });
// });

})


/*
|
| Enter
|-------------
*/
barba.hooks.enter((data) => {
    ScrollTrigger.refresh(data.next.container);
    if (window.matchMedia("(min-width:48rem)").matches) {
        const cursor = new Cursor(data.next.container);
    };    
})



/*
|
| After enter
|-------------
*/
barba.hooks.afterEnter((data) => {

    setTimeout(() => {
      if (window.matchMedia("(min-width:48rem)").matches) {
            Smoother();
            Footer(data.next.container);
            Menu_services();   
      };  
      Drag_sticker(data.next.container);
      Filtre_blog_active();
      accordeon(data.next.container);
      Slider_Web(data.next.container);
      Slider_Expertise(data.next.container);
     
    }, 100);
  
    setTimeout(() => {
      window.lazy.update();
      // if (window.matchMedia("(min-width:48rem)").matches) {
      // }; 
      Fade();
      Line();
      Pop();
     
    }, 200);
  });


/*
|
| After 
|-------------
*/
barba.hooks.after(() => {
    gtag('event', 'page_view', {
      'page_title': document.title,
      'page_location': location.href,
      'page_path': location.pathname,
    });
});


/*
|
| Barba Routing 
|--------------
*/
barba.use(barbaPrefetch);
barba.init({
    timeout: 15000,
    preventRunning: true,
    prevent: ({
        event,
        href
    }) => {
        if (event.type !== 'click') {
            return false;
        }

        if (href === window.location.href) {
            event.preventDefault();
            event.stopPropagation();
            return true;
        }

        const links = document.querySelectorAll('a');
        links.forEach(link => {
            link.classList.toggle('visited', link.href === window.location.href);
        });
    },


    transitions: [{
        name: 'default-transition',
        sync: false,


        once(data){

          
        },
        leave(data) {

            var done = this.async()
            const leave = gsap.timeline({
                onComplete: () => {
                    done()
                    }	
                })
              
            var l1 = gsap.timeline()

              .set(overlayPath, {
                    attr: {
                      d: 'M 0 100 V 100 Q 50 100 100 100 V 100 z'
                    }
                })
              .to(overlayPath, {
                    duration: 0.8,
                    ease: 'power2.in',
                    attr: {
                      d: 'M 0 100 V 60 Q 50 20 100 60 V 100 z'
                    }
                })
              .to(overlayPath, {
                    duration: 0.8,
                    ease: 'power2.out',
                    attr: {
                      d: 'M 0 100 V 0 Q 50 0 100 0 V 100 z'
                    }
                 
                });
        
            leave.add(l1)
          
        },	
        
        
        after(data) {				                   
            var done = this.async()
            const enter = gsap.timeline({
                onComplete: () => {
                    done()
                    }	
                })
              
            var e1 = gsap.timeline()

              .set(overlayPath, {
                    attr: {
                      d: 'M 0 0 V 100 Q 50 100 100 100 V 0 z'
                    },
                
                })
                
              .to(overlayPath, {
                    duration: 0.8,
                    ease: 'power2.in',
                    attr: {
                      d: 'M 0 0 V 36 Q 48 84 100 36 V 0 z'
                    }
                })

              .to(overlayPath, {
                    duration: 0.8,
                    ease: 'power2.out',
                    attr: {
                      d: 'M 0 0 V 0 Q 50 0 100 0 V 0 z'
                    },   
                });
        
            enter.add(e1)

                      // Contact form 7 
          var cf_selector = 'div.wpcf7 > form';
          var cf_forms = $(data.next.container).find(cf_selector);
          if (cf_forms.length > 0) {
              $(cf_selector).each(function() {
                  var $form = $(this);
                  wpcf7.init($form[0]);
              });
          }
          
        }
    }],

    views: [
        Home(),
        About(),
        Contact(),
        Faq(),
        News(),
        NewsCat(),
        Article(),
        Project(),
        Projects(),
        Expertise()
    ]
})





