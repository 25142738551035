/*** IMPORTS FROM imports-loader ***/
var define = false;

import gsap from "gsap";
import barba from "@barba/core";

export default class Cursor {
  constructor(data) {
    this.cursorTag = document.querySelector(".cursors");
    this.balls = this.cursorTag.querySelectorAll(".cursors_inner");
    this.svg = this.cursorTag.querySelectorAll(".cursors_inner > lottie-player");
    this.ballMessage = this.cursorTag.querySelector("span");

    this.aimX = 0;
    this.aimY = 0;
    this.isAnimating = false;
    this.isOverScaleElement = false;
    this.scaleAnimation = null;

    this.init();
    this.bindEvents();
  }

  init() {
    this.balls.forEach((ball, index) => {
      let currentX = 0;
      let currentY = 0;
      let speedX = 0.1;
      let speedY = 0.1;

      const animate = () => {
        currentX += (this.aimX - currentX) * speedX;
        currentY += (this.aimY - currentY) * speedY;

        ball.style.left = currentX + "px";
        ball.style.top = currentY + "px";

        requestAnimationFrame(animate);
      };
      animate();
    });
  }

  bindEvents() {
    window.addEventListener("mousemove", (event) => {
      this.aimX = event.clientX;
      this.aimY = event.clientY;

      if (this.isOverScaleElement && !this.isAnimating) {
        this.enlargeCursor();
      } else if (!this.isOverScaleElement && this.isAnimating) {
        this.shrinkCursor();
      }
    });

    document.querySelectorAll("[data-cursor-scale]").forEach((image) => {
      image.addEventListener("mouseenter", () => {
        this.isOverScaleElement = true;
        this.enlargeCursor();
      });

      image.addEventListener("mouseleave", () => {
        this.isOverScaleElement = false;
        this.shrinkCursor();
      });
    });

    // Ajoutez l'écouteur d'événement pour réinitialiser le curseur lors de la transition de page avec Barba.js
    barba.hooks.enter(() => {
      this.isOverScaleElement = false;
      this.shrinkCursor();
    });
  }

  enlargeCursor() {
    if (this.scaleAnimation) {
      this.scaleAnimation.kill();
    }

    this.isAnimating = true;
    this.scaleAnimation = gsap.to(this.balls, {
      width: "5em",
      height: "5em",
      duration: 0.8,
      ease: "elastic.out(2.5, 0.6)",
      onStart: () => {
        gsap.to(this.svg, {
          opacity: 1,
          duration: 0.2,
        });
      },
      onComplete: () => {
        this.isAnimating = false;
      },
    });
  }

  shrinkCursor() {
    if (this.scaleAnimation) {
      this.scaleAnimation.kill();
    }

    this.isAnimating = true;
    this.scaleAnimation = gsap.to(this.balls, {
      width: "0em",
      height: "0em",
      duration: 0.3,
      ease: "Circ.out",
      onStart: () => {
        gsap.to(this.svg, {
          opacity: 0,
          duration: 0.2,
        });
      },
      onComplete: () => {
        this.isAnimating = false;
      },
    });
  }
}

const cursor = new Cursor();

