/*** IMPORTS FROM imports-loader ***/
var define = false;

import { gsap } from "gsap/all";

let animation;
   
function enter() {
    animation = gsap.timeline();
    
    animation.to([".line-up"], {
    y:"0%",
    opacity:1,
    stagger:0.08,
    ease:"Circ.out",
    }, 0);
    
}
    
function resetAnimation() {
    if(animation) {
    animation.progress(0);
    animation.kill();
    }
    enter();
}

const NewsCat = () => {

    return {
        namespace: 'archive-category',

        beforeLeave() {

        },
        afterLeave() {

        },
        beforeEnter(data) {
            resetAnimation();
        },
        afterEnter(data) {

            
        }
    }
}

export default NewsCat
