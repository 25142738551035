/*** IMPORTS FROM imports-loader ***/
var define = false;


import { Scroll_opacity, Home_Section_Expertises_Web, Home_Section_Expertises_Mobile, Panel_fixed } from '@components/section_effect';
import { Eyes_Cursor_Track } from '@components/eyes_cursor_track';
import { gsap } from "gsap/all";

let animation;

function open() {
  gsap.set(".sticker", {
    scale: 0
  });
}

function enter() {
  animation = gsap.timeline();

  animation.to([".line-up"], {
    y: "0%",
    duration: 0.8,
    opacity: 1,
    stagger: 0.1,
    delay: 0.4,
    ease: "Circ.out",
  }, 0);

  animation.to(".sticker", {
    scale: 1,
    duration: 1,
    ease: "elastic.out(1, 0.6)",
  }, 1);
}

function resetAnimation() {
  if (animation) {
    animation.progress(0);
    animation.kill();
  }
  open();
  enter();
}



const Contact = () => {
 
  
    return {
      namespace: 'contact',
  
      beforeLeave() {
   
      },
  
      afterLeave() {
      
      },
  
      beforeEnter(data) {
        if (window.matchMedia("(min-width:48rem)").matches) {
          Scroll_opacity(data.next.container);
          Panel_fixed(data.next.container);
        } 

        const form_line = document.querySelectorAll("input.wpcf7-text");
        const div = document.createElement("div");
        div.className = "input-line";
        form_line.forEach(element => {
          element.parentNode.appendChild(div.cloneNode());
        });
      },
  
      afterEnter(data) {
        resetAnimation(data.next.container);

     
  
      }
    };
  };
  
  export default Contact;





