/*** IMPORTS FROM imports-loader ***/
var define = false;

import {Scroll_horizontal_block} from '@components/section_effect';
import { gsap } from "gsap/all";

let animation;
   
function enter() {
    animation = gsap.timeline();
    
    animation.to([".line-up"], {
    y:"0%",
    opacity:1,
    stagger:0.08,
    duration:0.8,
    ease:"Circ.out",
    }, 0);
    
}
    
function resetAnimation() {
    if(animation) {
    animation.progress(0);
    animation.kill();
    }
    enter();
}

const About = () => {

    return {
        namespace: 'about',

        beforeLeave() {
        },

        afterLeave() {
        },

        beforeEnter(data) {
            resetAnimation();
            if (window.matchMedia("(min-width:48rem)").matches) {
                Scroll_horizontal_block(data.next.container);
              };   
        },

        afterEnter() { 
          
        }
    }
}

export default About
