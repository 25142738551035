/*** IMPORTS FROM imports-loader ***/
var define = false;

import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

let singleContentArticle;

const initArticleEvents = (container) => {
  const progress = container.querySelector('progress');
  singleContentArticle = container.querySelector('.single_content');
  let maxProgress = singleContentArticle.offsetHeight - window.innerHeight;
  progress.max = maxProgress;

  const recalculateMaxProgress = () => {
    maxProgress = singleContentArticle.offsetHeight - window.innerHeight;
    progress.max = maxProgress;
  };

  window.addEventListener('resize', recalculateMaxProgress);

  ScrollTrigger.create({
    trigger: '.single_content',
    start: 'top top',
    end: 'bottom bottom',
    onUpdate: (self) => {
      progress.value = self.progress * maxProgress;
    }
  });

  /*
  |
  | Sticky navigation
  |-----------------------
  */

  const singleContent = container.querySelector('.single_content');
  const stickyBlock = container.querySelector('#sticky_block');
  const stickyBlockHeight = stickyBlock.offsetHeight + 100;

  ScrollTrigger.create({
    trigger: singleContent,
    endTrigger: singleContent,
    start: "top 100",
    end: `bottom ${stickyBlockHeight}`,
    pin: stickyBlock,
    pinSpacing: false
  }).refresh(container);
};

const Article = () => {
  return {
    namespace: 'single-article',

    beforeLeave() {},
    afterLeave() {},
    beforeEnter() {
    },
    afterEnter(data) {
      if (window.matchMedia("(min-width:48rem)").matches) {
        initArticleEvents(data.next.container);
      }; 
      
    }
  }
};

export default Article;

