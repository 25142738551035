/*** IMPORTS FROM imports-loader ***/
var define = false;

import {
  gsap,
  ScrollTrigger,
  Draggable,
  InertiaPlugin
} from "gsap/all";
gsap.registerPlugin(ScrollTrigger, Draggable, InertiaPlugin);
import * as LottiePlayer from "@lottiefiles/lottie-player";
/*
|
| Homepage
|----------------------
*/
export function Home_Section_Expertises_Web(container) {
  const expertises_parent = container.querySelector(".home_section_expertises");
  const expertise = Array.from(container.querySelectorAll('.home_section_expertise_content'));
  const lottiePlayers = Array.from(container.querySelectorAll('.home_section_expertise_content lottie-player'));

  let currentIndex = 0;

  // Ajoute la classe "is-active" à la première expertise
  expertise[0].classList.add('is-active');

  // Crée le ScrollTrigger pour la section d'expertises
  ScrollTrigger.create({
    trigger: expertises_parent,
    pin: true,
    end: function () {
      return '+=' + window.innerHeight / 0.6 * expertise.length;
    },
    toggleClass: 'is-active',
    onEnter: function () {
      expertise[0].classList.add('is-active');
      // Lance l'animation de la balise Lottie Player lorsque la section est active
      lottiePlayers[currentIndex].play();
    },
    onLeaveBack: function () {
      expertise.forEach(e => e.classList.remove('is-active'));
      expertise[0].classList.add('is-active');
      // Met en pause l'animation de la balise Lottie Player lorsque l'on revient en arrière
      lottiePlayers[currentIndex].pause();
    },
    onUpdate: function (self) {
      const previousIndex = currentIndex;
      currentIndex = Math.floor(self.progress * expertise.length);

      if (previousIndex >= expertise.length || currentIndex >= expertise.length)
        return;

      if (previousIndex !== currentIndex) {
        expertise.forEach(e => e.classList.remove('is-active'));
        expertise[currentIndex].classList.add('is-active');
        // Met en pause l'animation de la balise Lottie Player précédente et lance celle de la nouvelle expertise active
        lottiePlayers[previousIndex].stop();
        lottiePlayers[currentIndex].setDirection(1);
        lottiePlayers[currentIndex].play();
      }
    },
  });
}

export function Home_Section_Expertises_Mobile(container) {
  const expertises = Array.from(container.querySelectorAll('.home_section_expertise_content'));
  const lottiePlayers = Array.from(container.querySelectorAll('.home_section_expertise_content .expertise_content_sticky lottie-player'));

  let currentSectionIndex = -1;

  // Fonction pour mettre en pause tous les Lottie Players
  function pauseAllLottiePlayers() {
    lottiePlayers.forEach(player => player.pause());
  }

  // Crée le ScrollTrigger pour chaque section d'expertise
  expertises.forEach((section, index) => {
    ScrollTrigger.create({
      trigger: section,
      start: 'top center',
      end: 'bottom center',
      onEnter: function () {
        // Met en pause tous les Lottie Players
        pauseAllLottiePlayers();

        // Joue le Lottie Player de la section actuelle
        const lottiePlayer = lottiePlayers[index];
        if (lottiePlayer) {
          lottiePlayer.play();
        }

        // Met à jour l'index de la section actuelle
        currentSectionIndex = index;
      },
      onEnterBack: function () {
        // Met en pause tous les Lottie Players
        pauseAllLottiePlayers();

        // Joue le Lottie Player de la section actuelle
        const lottiePlayer = lottiePlayers[index];
        if (lottiePlayer) {
          lottiePlayer.play();
        }

        // Met à jour l'index de la section actuelle
        currentSectionIndex = index;
      },
      onLeave: function () {
        // Met en pause tous les Lottie Players
        pauseAllLottiePlayers();

        // Reprend la lecture du Lottie Player de la section précédente
        const lottiePlayer = lottiePlayers[currentSectionIndex];
        if (lottiePlayer) {
          lottiePlayer.play();
        }
      },
    });
  });
}

/*
|
| Panel opacity 
|----------------------
*/
export function Scroll_opacity(container) {
  const intro = container.querySelector(".panel-top");
  const about = container.querySelectorAll(".panel-bottom");
  
  gsap.from(about, {
    scrollTrigger: {
      trigger: intro,
      scrub: true,
      markers: false,
      start: "bottom bottom",
      onUpdate: (self) => {
        gsap.to(intro, {
          opacity: 1 - (self.progress * 0.9)
        });
      }
    }
  });
}

/*
|
| Scroll horizontale team
|-------------------------------
*/



/*
|
| Scroll horizontale expertise
|-------------------------------
*/

export function Scroll_horizontal_block(container) {
  const pinnedImageWrappers = container.querySelectorAll('.js-pinned-wrap');

  if (pinnedImageWrappers) {
    pinnedImageWrappers.forEach((wrapper) => {
      const inner = wrapper.querySelector('.js-pinned-inner');

      gsap.to(inner, {
        x: () => -(inner.scrollWidth - document.documentElement.clientWidth) + 'px',
        ease: 'none',
        scrollTrigger: {
          start: 'center center',
          trigger: '.pinned-inner',
          pin: '.container_pinned_horizontal',
          pinSpacing: true,
          scrub: true,
          invalidateOnRefresh: true,
          end: () => `+=${inner.offsetWidth}`,
          horizontal: false // Ajouter cette option pour distinguer le scroll horizontal
        }
      });
    });
  }
}

/*
|
| Fade effect
|-----------------------
*/
export function Fade() {
  gsap.utils.toArray('[fade]').forEach(elem => {
    gsap.fromTo(elem, {
      opacity: 0,
      y: 40
      }, {
      opacity: 1,
      y: 0,
      scrollTrigger: {
        trigger: elem,
        start: "top 100%",
        end: "bottom 100%",
        onEnter: () => {
          gsap.to(elem, {
            duration: 0.8, 
            opacity: 1, 
            ease:"Circ.easeout",
            y: 0});
        }
      }
    });
  });
}

/*
|
| Line effect
|-----------------------
*/
export function Line() {
  gsap.utils.toArray('[line]').forEach(elem => {
    gsap.fromTo(elem, {
      transformOrigin: 'left',
      scaleX: 0
      }, {
      scaleX: 1,
      duration: 1.4,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: elem,
        start: 'top 90%',
        end: 'bottom 50%'
      }
    });
  });
}

/*
|
| Pop effect
|-----------------------
*/
export function Pop() {
  gsap.utils.toArray('[pop]').forEach(elem => {
    gsap.fromTo(elem, {
      transformOrigin: 'center',
      scaleX: 0,
      scaleY:0
      }, {
      scaleX: 1,
      scaleY:1,
      duration: 1.4,
      ease:"elastic.out(1, 0.6)",
      scrollTrigger: {
        trigger: elem,
        start: 'top 90%',
        end: 'bottom 50%'
      }
    });
  });
}

/*
|
| Stack effect
|----------------------
*/
export function Panel_fixed() {

  const panels = gsap.utils.toArray('.panel');

  panels.forEach(panel => {
    gsap.to(panel, {
      opacity: 1,
      y: 0,
      scrollTrigger: {
        trigger: panel,
        scrub: true,
        start: () => panel.offsetHeight > window.innerHeight ? "bottom bottom" : "top top",
        pin: true,
        pinSpacing: false,
      }
    });
  });
  
}

/*
|
| Draggable stickers
|----------------------
*/
export function Drag_sticker(container) {
  let sticker = container.querySelectorAll('.sticker')

  Draggable.create(sticker, {
    type:"x,y",
    bounds:".page-wrapper",
    overshootTolerance:1,
    inertia:true
  })
  
}

/*
|
| Footer fixe
|----------------------
*/
export function Footer(){
  ScrollTrigger.create({
    trigger: "footer",
    pin: true,
    start: "bottom bottom",
    end: "+=100%"
  });
}








