/*** IMPORTS FROM imports-loader ***/
var define = false;

import { gsap } from "gsap/all";

let animation;



function enter() {
    animation = gsap.timeline();
    
    animation.to([".line-up"], {
        y:"0%",
        opacity:1,
        stagger:0.08,
        duration:0.8,
        ease:"Circ.out",
    },0);


    
}
    
function resetAnimation() {
    if(animation) {
    animation.progress(0);
    animation.kill();
    }

    enter();
}

const Projects = () => {

    return {
        namespace: 'archive-projects',

        beforeLeave() { },
        afterLeave() { },
        beforeEnter() {
            
        },
        afterEnter() {
            resetAnimation()
        }
  
    }
    
}

export default Projects
