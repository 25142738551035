/*** IMPORTS FROM imports-loader ***/
var define = false;

import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

const initArticleEvents = (container) => {

  const singleContent = container.querySelector('.single_content');
  const stickyBlock = container.querySelector('#sticky_block');
  const stickyBlockHeight = stickyBlock.offsetHeight + 100;

  ScrollTrigger.create({
    trigger: singleContent,
    endTrigger: singleContent,
    start: "top 100",
    end: `bottom ${stickyBlockHeight}`,
    pin: stickyBlock,
    pinSpacing: false
  }).refresh(container);
};

let animation;

function open() {
    gsap.set(".sticker", {
    scale:0
    })
}

function enter() {
  animation = gsap.timeline();

  animation.to(".sticker", {
      scale:1,
      duration:1,
      stagger:0.2,
      ease:"elastic.out(1, 0.6)",
  });
  
}


function resetAnimation() {
  if(animation) {
  animation.progress(0);
  animation.kill();
  }
  open();
  enter();
}



const Project = () => {
  return {
    namespace: 'single-project',

    beforeLeave() {},
    afterLeave() {},
    beforeEnter() {
    },
    afterEnter(data) {
      if (window.matchMedia("(min-width:48rem)").matches) {
        initArticleEvents(data.next.container);
      }; 
      resetAnimation()
      
    }
  }
};

export default Project;

