/*** IMPORTS FROM imports-loader ***/
var define = false;


import Swiper from 'swiper';
import {
  gsap,
  ScrollTrigger
} from "gsap/all";
gsap.registerPlugin(ScrollTrigger);


/*
|
| Slider
|----------------------
*/
export function Slider_Web(container) {

  ScrollTrigger.config({
    autoRefreshEvents: 'visibilitychange,DOMContentLoaded,load, resize'
  });

 // Marquee effect
const marquee = container.querySelectorAll('.block_marquee_scroll');

marquee.forEach((e) => {
  // Create swiper carousel
  const carousel = e.querySelectorAll('.marquee-carousel');

  carousel.forEach((e) => {
    const items = e.querySelector('.marquee-items');
    const item = e.querySelectorAll('.marquee-item');

    e.classList.add('swiper-container');
    items.classList.add('swiper-wrapper');
    item.forEach((e) => e.classList.add('swiper-slide'));

    const slider = new Swiper(e, {
      slidesPerView: 'auto',
      loop: true,
      freeMode: true,
      freeModeMomentumBounce: false,
      freeModeMomentumVelocityRatio: 0.2
    });
  });

  // Scroll triggered movement
  const tl = new gsap.timeline();

  tl.set(carousel, { willChange: 'transform' });

  tl.fromTo(
    carousel[0],
    {
      x: -600
    },
    {
      x: 0,
      ease: 'none'
    },
    0
  );

  tl.fromTo(
    carousel[1],
    {
      x: 600
    },
    {
      x: 0,
      ease: 'none'
    },
    0
  );

  tl.set(carousel, { willChange: 'auto' });

  ScrollTrigger.create({
    trigger: e,
    animation: tl,
    start: 'top-=60% bottom',
    end: 'bottom-=60% top',
    scrub: 0.3,
    refreshPriority: -14
  });
});

}


export function Slider_Expertise(container){

  const Next = document.querySelector('.swiper-button-next');
  const Prev = document.querySelector('.swiper-button-prev');

  var Slider_Exp = new Swiper(".swiper-container", {
    roundLengths: true,
    loop: false,
    observer: true,
    observeParents: true,
    slidesPerView: 'auto',
    paginationClickable: true,
    grabCursor: true,
    loopAdditionalSlides: 30,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });

}
