/*** IMPORTS FROM imports-loader ***/
var define = false;

import { gsap } from "gsap/all";

const megaMenuBg = document.querySelector(".megamenu_services_bg");
const megaMenu = document.querySelector(".megamenu_services_content");
let isMenuOpen = false;
let isAnimating = false;

export function showMegaMenu() {
  if (isAnimating) return;

  isAnimating = true;

  gsap.to(megaMenu, {
    y: 0,
    duration: 0.8,
    ease: "power2.inOut",
    onComplete: () => {
      isMenuOpen = true;
      isAnimating = false;
    }
  });

  gsap.to(megaMenuBg, {
    opacity: 1,
    autoAlpha: 1,
    display: "block",
    duration: 0.8,
    ease: "power2.out"
  });

  gsap.from(".megamenu_services_inner", {
    y:"-20%",
    opacity:0,
    delay:0.3,
    stagger:0.15,
    duration: 0.8,
    ease: "power2.out"
  });
}

export function hideMegaMenu() {
    if (isAnimating) return; // Vérifier si une animation est en cours
    
    isAnimating = true; // Marquer qu'une animation est en cours
    
    gsap.to(megaMenu, {
      y: "-100%",
      duration: 0.7,
      ease: "power2.inOut",
      onUpdate: () => {
        isMenuOpen = false;
        isAnimating = false; // Marquer que l'animation est terminée
        // Retirer la classe "is-visible" du lien qui a déclenché le méga menu
        const activeLink = document.querySelector(".is-visible");
        if (activeLink) {
          activeLink.classList.remove("is-visible");
        }
      }
    });
    
    gsap.to(megaMenuBg, {
      opacity: 0,
      display: "none",
      autoAlpha: 0,
      duration: 0.8,
      ease: "power2.in",
    });
}

export function Menu_services() {
  if (megaMenu) {
    const open = document.querySelectorAll(".hover_megaMenu");
    open.forEach((menuItem) => {
      menuItem.addEventListener("click", () => {
        if (isMenuOpen) {
          hideMegaMenu();
        } else {
          showMegaMenu();
          menuItem.classList.add("is-visible");
        }
      });
    });

    megaMenuBg.addEventListener("mouseenter", () => {
      if (isMenuOpen) {
        hideMegaMenu();
      }
    });

  }
}



const links = document.querySelectorAll('.megamenu_services_inner');

links.forEach(link => {
  const lottiePlayer = link.querySelector('lottie-player');

  link.addEventListener('mouseenter', () => {
    lottiePlayer.play();
  });

  link.addEventListener('mouseleave', () => {
    lottiePlayer.stop();
  });
});
