/*** IMPORTS FROM imports-loader ***/
var define = false;

import {Scroll_horizontal_block, Panel_fixed } from '@components/section_effect';
import { gsap } from "gsap/all";

let animation;

function open() {
  gsap.set(".sticker", {
    scale: 0
  });
}

function enter() {
  animation = gsap.timeline();

  animation.to([".line-up"], {
    y: "0%",
    duration: 0.8,
    opacity: 1,
    stagger: 0.1,
    ease: "Circ.out",
  }, 0);

  animation.to(".sticker", {
    scale: 1,
    duration: 1,
    ease: "elastic.out(1, 0.6)",
  }, 0);
}

function resetAnimation() {
  if (animation) {
    animation.progress(0);
    animation.kill();
  }
  open();
  enter();
}

const Expertise = () => {
  return {
    namespace: 'single-expertise',

    beforeLeave() {},
    afterLeave() {},
    beforeEnter(data) {
      if (window.matchMedia("(min-width:48rem)").matches) {
        Scroll_horizontal_block(data.next.container);
        Panel_fixed(data.next.container);
      };  
    },
    afterEnter() {
      resetAnimation();    
    }
  }
};

export default Expertise;

