/*** IMPORTS FROM imports-loader ***/
var define = false;

import { gsap } from "gsap/all";

let animation;

function open() {
    gsap.set(".sticker", {
    scale:0
    })
}


function enter() {
    animation = gsap.timeline();
    
    animation.to([".line-up"], {
        y:"0%",
        opacity:1,
        stagger:0.08,
        duration:0.8,
        delay:0.2,
        ease:"Circ.out",
    }, 0);

    animation.to(".sticker", {
        scale:1,
        duration:1,
        stagger:0.2,
        ease:"elastic.out(1, 0.6)",
    },1);
    
}
    
function resetAnimation() {
    if(animation) {
    animation.progress(0);
    animation.kill();
    }
    open();
    enter();
}

const Faq = () => {

    return {
        namespace: 'faq',

        beforeLeave() {
        },
        afterLeave() {
        },
        beforeEnter(data) {

        },
        afterEnter(data) {
            resetAnimation()
        }
    }
}

export default Faq
