/*** IMPORTS FROM imports-loader ***/
var define = false;

import LazyLoad from 'vanilla-lazyload'
import { gsap } from "gsap/all";

const Main = () => {

    /*
    |
    | Constants
    |-----------
    */

    const
        $window = $(window),
        $body = $('body'),
        $burger = $('.burger'),
        $megamenu = $("#megamenu_services")
        ;

    /*
    |
    | Esteregg
    |-----------------------
    */
 
    const images = document.querySelectorAll('.easteregg__image');
    const eastereggs = document.querySelector('.easteregg');

    const tl = gsap.timeline({ paused: true });
    tl.set(eastereggs, {display: 'block'});
    tl.to(images, { 
        scale: 1.3,
        duration: 0.3, 
        ease: "power1.out",
        stagger:0.02 
    });

    const playEastereggs = document.querySelector('#audit_section');
    playEastereggs.addEventListener('mouseenter', () => {
        tl.play();
    });

    playEastereggs.addEventListener('mouseleave', () => {
        tl.reverse();
    });
   

    /*
    |
    | LazyLoad
    |-----------------
    */

    function lazyParent(el) {
        const lazyContainer = el.closest(".lazy-container")
        lazyContainer.classList.add("is-load")
    }

    window.lazy = new LazyLoad({
        unobserve_entered: true,
        callback_loaded: lazyParent
    })



    
    /*
    |
    | Burger Menu
    |-----------------
    */

    $burger.on('click', function () {
        $body.toggleClass('is-menu')
    })

    function checkDevice() {
        if ("matchMedia" in window) {
            if (window.matchMedia("(min-width:48rem)").matches) {
                $body.removeClass('is-menu')
            }
        }
    }
    window.addEventListener('resize', checkDevice, true);

    /*
    |
    | End
    |-----------------
    */
}
export default Main
